import axios from "axios";
import {store} from "../store/store"
import {AuthActions, idleTimer} from "../store/authModule"


const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("user_token");
    config.headers = {
      Authorization: `Bearer ${token}`,
      //   Accept: "application/json",
      //   "Content-Type": "application/x-www-form-urlencoded",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    idleTimer.reset();
    return response;
  },
  async function (error) {
    if (error.response.status === 401) {
      // location.reload();
      store.dispatch(AuthActions.LOG_OUT);
      return Promise.reject({ notAuthenticated: true });
    }
    return Promise.reject({ error });
  }
);

export const axiosInstance = axiosApiInstance;
